.slick-prev::before {
  color: #EE6352;
}

.slick-next::before {
  color: #EE6352;
}

.slick-slide {
  padding: 15px;
  cursor: pointer;
}
